@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,400;0,600;0,700;1,600&display=swap');

*{
  font-family: 'Lora', sans-serif;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}